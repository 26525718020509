.root {
  background-color: #fff;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  min-width: 280px;
  z-index: 1;
  padding: 24px;
  @media (max-width: 1150px) {
    display: none;
  }
}
.toggleNav {
  display: none;
  @media (max-width: 1150px) {
    display: block;
    //  background-color: lightcoral;
    z-index: 1;
    position: fixed;
    top: 1rem;
    left: 0rem;

    .MuiSvgIcon-root {
      font-size: 40px;
    }
  }
}
