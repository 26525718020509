// ****************************** Landing Page ********************************** //

.div-01 {
  position: relative;
}

.get-assured {
  display: flex;
  width: 80%;
  min-width: 1140px;
  margin: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 16px rgba(159, 209, 255, 0.08);
  backdrop-filter: blur(80px);
  border-radius: 20px;
  border: 1px solid rgba(240, 240, 240, 0.8);
  border-top: none;

  @media (max-width: 1175px) {
    width: calc(100vw - 2vw) !important;
    min-width: auto !important;
  }

  @media (max-width: 800px) {
    margin-top: 3rem;
  }

  @media (max-width: 600px) {
    min-height: 10rem;
    border: none;
    margin-bottom: 1rem;
  }

  span {
    width: 50%;
    min-width: 570px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 2rem;

    @media (max-width: 1000px) {
      min-width: auto !important;
      width: 800px;
    }

    @media (max-width: 600px) {
      width: 550px;
    }

    @media (max-width: 550px) {
      width: 21rem;
      padding-left: 10px;
    }

    h3 {
      font-weight: 800;
      font-size: 40px;
      letter-spacing: 0.02em;
      color: #52575c;
      line-height: 43px;

      @media (max-width: 1000px) {
        font-size: 35px;
        line-height: 35px;
      }
      @media (max-width: 1175px) {
        font-size: 25px;
        line-height: 28px;
        width: 20rem;
      }
      @media (max-width: 550px) {
        font-size: 18px;
        width: 16rem;
      }
      @media (max-width: 420px) {
        font-size: 14px;
        width: 14rem;
        line-height: 20px;
        margin-top: 2rem;
      }
    }

    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #52575c;
      margin-top: 20px;
      @media (max-width: 1000px) {
        font-size: 18px;
        line-height: 35px;
      }

      @media (max-width: 1175px) {
        font-size: 16px;
        line-height: 35px;
        margin-top: 10px;
      }

      @media (max-width: 550px) {
        font-size: 14px;
      }

      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
  }
}

.illustration {
  width: 50%;
  min-width: 570px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 29rem;
  }

  @media (max-width: 1165px) {
    img {
      min-width: none !important;
      width: calc(100vw - 62vw);
    }
  }
  @media (max-width: 1000px) {
    min-width: auto !important;
    width: 68%;
  }

  @media (max-width: 970px) {
    img {
      width: calc(100vw - 68vw);
    }
  }
  @media (max-width: 600px) {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 90px;
  }
}

.gradiant {
  background: rgba(50, 36, 211, 0.37);
  filter: blur(600px);
  width: 100%;
  height: 2rem;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: -2;
  transform: translate3d(0, 0, -100);
}

.tower-01 {
  position: absolute;
  left: -82px;
  top: 52%;

  @media (max-width: 600px) {
    display: none;
  }
}
.tower-02 {
  position: absolute;
  left: 68px;
  top: 69%;
  z-index: -2;
  transform: translate3d(0, 0, -100);
  @media (max-width: 600px) {
    display: none;
  }
}
.tower-03 {
  position: absolute;
  right: 0.5%;
  top: 40%;
  @media (max-width: 600px) {
    display: none;
  }
}

// ****************************** div 02 ********************************** //

.div-02 {
  overflow: hidden;
  position: relative;
  margin-top: 6rem;
  width: 100%;
  height: 432px;
  background: #8255ff;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 702px;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #f6f6f6;

    @media (max-width: 767px) {
      font-size: 20px;
      width: 502px;
    }

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 20px;
      width: 400px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
      width: 300px;
    }
  }

  @media (max-width: 700px) {
    height: calc(100vw - 60vw);
    min-height: 14rem;
  }
}

.div-02__tower-01 {
  position: absolute;
  left: 3%;
  top: 7%;
  width: 10rem;
  @media (max-width: 767px) {
    left: 2%;
    top: 15%;
    width: 6rem;
  }

  @media (max-width: 550px) {
    width: 4rem;
    top: 30%;
  }
}
.div-02__tower-02 {
  position: absolute;
  top: 50%;
  left: 15%;
  width: 7rem;
  @media (max-width: 767px) {
    top: 40%;
    left: 15%;
    width: 5rem;
  }
  @media (max-width: 550px) {
    width: 4rem;
  }
}
.div-02__tower-03 {
  position: absolute;
  right: 18%;
  top: 10%;
  @media (max-width: 767px) {
    right: 18%;
    top: 25%;
    width: 5rem;
  }
  @media (max-width: 550px) {
    width: 4rem;
    top: 30%;
  }
}
.div-02__tower-04 {
  position: absolute;
  width: 12rem;
  right: 0%;
  top: 27%;
  @media (max-width: 767px) {
    width: 8rem;
    right: 0%;
    top: 35%;
  }
  @media (max-width: 550px) {
    width: 4rem;
    right: 2%;
  }
}
