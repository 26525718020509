.InAppPreview {
  width: 100%;
  height: 100%;
}

.Preview {
  margin-top: 2.8rem;
  width: 360px;
  height: 224px;
  background-color: #34373e;
  overflow: hidden;

  @media (max-width: 450px) {
    margin-top: 1.5rem;
    width: calc(100vw - 22vw);
    height: 185px;
  }
}

.Preview-content {
  height: 100%;
  margin-top: 4rem;
  border-radius: 40px;
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5);

  display: flex;
  padding-top: 2.5rem;
  padding-left: 2.5rem;

  @media (max-width: 450px) {
    margin-top: 3rem;
    padding-top: 2rem;
    padding-left: 2rem;
  }

  div {
    h5 {
      font-weight: 500;
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.4px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #cbcbcb;
      width: 200px;
    }
    @media (max-width: 450px) {
      h5 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
      }
    }
  }
}

.Preview-logo-sample {
  background-color: #fff;
  width: 68.5px;
  height: 68.5px;
  border-radius: 50%;
  margin-left: -15px;
  margin-right: 10px;
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 15px !important;
  color: #938484;

  @media (max-width: 450px) {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    font-size: 12px !important;
  }
}

.Preview-logo {
  border-radius: 50% !important;
  margin-left: -15px;
  margin-right: 15px;
  margin-top: -10px;
  .MuiAvatar-root {
    width: 70px;
    height: 70px;
    @media (max-width: 450px) {
      width: 50px;
      min-width: 50px;
      height: 50px;
      min-height: 50px;
    }
  }
  img {
    object-fit: contain;
  }
}
