.footer {
  background-color: #fff;
  width: 100%;
  height: 350px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;

  a {
    text-decoration: none;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #52575c;
    cursor: pointer;

    @media (max-width: 700px) {
      margin: 5px 0;
      margin-top: 0;
    }
    @media (max-width: 550px) {
      font-size: 14px !important;
    }
  }
}

.f-01 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 720px;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.f-02 {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 700px;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
