input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*{
  font-family: "Poppins", sans-serif !important;
}

.dashboard {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  overflow: hidden;
  font-family: "Poppins", sans-serif !important;

  &-content {
    background-color: #f4f6fa;
    flex: 1;
    padding: 1rem 2rem;
    overflow: hidden;

    @media (max-width: 500px) {
      padding: 4rem 1rem;
    }
    .headbar {
      display: flex;
      width: 100%;
      // justify-content: flex-end;
      // align-items: center;
      height: 4rem;
      // background: #000;
      padding: 2rem 0rem;

      align-items: center;
      .backbtn {
        align-items: center;
        display: flex;
        height: 40px;
        width: 40px;
      }
      .search_box {
        align-items: center;
        display: flex;
        height: 40px;
        width: 350px;
        background: #ffffff;
        border-radius: 100px;
        .searchIcon {
          margin: 10px;
          margin-right: 0;
        }
        input {
          flex: auto;
          padding-right: 0.75rem;
          outline: none;
          border: none;
          font-size: 14px;
          font-weight: 300;
          display: flex;
          align-items: center;
          justify-content: left;
          padding-left: 5px;
        }
      }
      .right__headbar {
        display: flex;
        justify-content: flex-end;
        flex: auto;
        // align-items: center;
        height: 4rem;
        // background: #000;
        padding: 2rem 0rem;
        width: 100%;
        align-items: center;

        img {
          height: 25px;
          margin-left: 0.25rem;
          margin-right: 1rem;
          width: 25px;
        }
        Avatar {
          margin: 0 0.5rem;
        }
      }
    }
    .headbar__help {
      display: flex;
      justify-content: space-around;
      margin: 0 5px;
      align-items: center;
      margin-right: 0.25;
    }
    .menu {
      display: flex;
      // justify-content: space-between;
      margin-top: 4rem;
      // align-items: center;
      flex-direction: column;
      h2 {
        color: #336cfb;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.2px;
        // color: #25282b;
        margin-bottom: 1rem;

        @media (max-width: 800px) {
          font-size: 15px;
        }
        @media (max-width: 500px) {
          font-size: 20px;
        }
      }
      .option1 {
        display: flex;
        // height: fit-content;
        // justify-content: space-between;
        padding: 1rem 0rem;

        // width: 70%;
        // @media (max-width: 800px) {
        //   width: 90%;
        // }
        // @media (max-width: 500px) {
        //   width: 100%;
        // }
        // align-items: center;
        .leftimg {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
          // background: gray;
        }

        .middle {
          display: flex;
          flex-direction: column;
          // flex: auto;
          height: 46px;
          width: 459px;

          h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            text-align: left;
            letter-spacing: 0.2px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
            letter-spacing: 0.2px;
          }
          // background: gray;
        }
        .radiobutton {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 60px;

          img {
            width: 100%;
            height: 100%;
          }
          // background: gray;
        }
      }
    }
    h1 {
      font-size: 40px;
      line-height: 46px;
      letter-spacing: 0.2px;
      color: #25282b;
      margin-bottom: 1.5rem;
      font-weight: 900 !important;
      font-family: "Poppins" !important;

      @media (max-width: 800px) {
        font-size: 30px;
      }
      @media (max-width: 500px) {
        font-size: 25px;
      }
    }
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #336cfb;
  margin-bottom: 12px;

  @media (max-width: 900px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;
  }
}
.edit__mode__addon
{
  padding:0 150px !important;
  .create-promoInfo__right
  {
    width:auto !important;
  }
  .preview
  {
    justify-content:space-between;
    .preview__right
    {
      width:auto !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    padding:0 !important;
  }
}
// ************************************** Custom Form  ************************************** //
.formcreate {
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  .left__form {
    flex: 0.3;
    display: flex;
    align-items: space-between;
    flex-direction: column;
    padding: 2rem;
    @media (max-width: 650px) {
      width: 100%;
    }
    justify-content: center;
    .top {
      display: flex;
      align-items: space-between;
      flex-direction: column;
      @media (max-width: 650px) {
        padding-bottom: 1rem;
      }
      h2 {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.2px;
        color: #336cfb;

        @media (max-width: 900px) {
          font-size: 16px;
        }
        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      TextField {
        border: 1px solid #336cfb;
        border-radius: 5px;
        height: 40px;
        font-size: 14px;
        padding: 5px 10px;
        margin: 10px 0px;
      }
    }
    .bottom {
      display: flex;
      align-items: space-between;
      justify-content: center;
      flex-direction: column;
      margin-top: 0.5rem;
      @media (max-width: 500px) {
        margin-bottom: 0;
      }
      h2 {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.2px;
        color: #336cfb;

        @media (max-width: 900px) {
          font-size: 16px;
        }
        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .imagepicker {
        display: flex;

        justify-content: center;
        align-items: center;
        padding: 0.5rem 0.2rem;
        @media (max-width: 500px) {
          margin: 10px 50px;
          margin-left: 100px;
          // width:100%;
        }
        h2 {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.2px;
          color: #336cfb;
          padding: 1px 3px;
          border-radius: 3px;
          background: rgba(188, 205, 250, 0.938);
          @media (max-width: 900px) {
            font-size: 14px;
          }
          @media (max-width: 500px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .btn {
        padding: 5px 10px;
        margin: 10px 50px;
        font-size: 12px;
        font-weight: bold;
        background-color: #336cfb;
        border-radius: 5px;
        color: #fafafa;
        @media (max-width: 650px) {
          margin: 10px 60px;
        }
      }
    }
  }

  .right__form {
    flex: 0.7;
    display: flex;
    // align-items: space-between;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    @media (max-width: 650px) {
      width: 100%;
    }
    h2 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.2px;
      color: #336cfb;

      @media (max-width: 900px) {
        font-size: 16px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    TextField {
      border: 1px solid #336cfb;
      border-radius: 5px;
      height: 90px;
      font-size: 14px;
      padding: 15px 20px;
      margin: 10px 0px;
    }
  }
  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
}


// ************************************** Table Style ************************************** //
.css-1psng7p-MuiTablePagination-spacer
{
  display: none;
}
// ************************************** Existing Page ************************************** //
.existing {
  background-color: transparent;
  width: 100%;
  overflow-x: hidden;
  .table {
    display: flex;
    flex-direction: column;
    // width: 100%;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      padding: 1rem;
      h2 {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
      }
    }
    .bottomnav {
      height: 60px;
      padding: 2rem 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .plusIcon {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        position: fixed;
        z-index: 999;
        cursor: pointer;
        right: 0px;
        margin: 20px;
        // padding: 20px;
        background-color: #336cfb;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        border: none;
        outline: none;
        color: #ffffff;
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
      .nav {
        display: flex;
        justify-content: center;
        align-items: center;

        .box {
          margin: 0.5rem 0.2rem;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f1f4ff;
          cursor: pointer;
          &:hover {
            background-color: #336cfb;
            color: #fff;
          }
        }
      }
    }
    .rowcontainer {
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
      flex: 1;
      .rowdata {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-weight: 400;
        width: 85%;

        h2 {
          text-align: center;
          font-size: 14px;
          font-weight: bold;

          line-height: 16px;
        }
      }
      .action {
        flex: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0.5rem;
        img {
          margin: 0.25rem 0.75rem;
          cursor: pointer;
        }
      }
    }
  }
}

// ************************************** Create Page ************************************** //

.create {
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;

  // ************************************** Promo Info ************************************** //

  &-promoInfo {
    display: flex;
    padding: 2rem 2.5rem;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-direction: column;
    }

    @media (max-width: 500px) {
      padding: 2rem 1rem;
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
        //  margin-top: 0.5rem;
      }

      span {
        display: flex;
        width: 20rem;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 500px) {
          width: 16rem;
        }

        button {
          width: 5rem;
          text-transform: capitalize;
          background-color: #336cfb;
          color: #fff;
          font-size: 13px;
          padding: 14px 24px;
          box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
          border-radius: 4px;
          line-height: 10px;

          &:hover {
            background-color: #336cfb;
          }
        }
      }
    }
  }

  // ************************************** Promo Budget ************************************** //

  &-promoBudget {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    padding: 1rem 2.5rem;

    span {
      margin-right: 1.5rem;
    }
  }
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 12px 24px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 4px;
  font-family: "Poppins";
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #336cfb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: max-content;

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
  }
}
.imagepicker {
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.2rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 4px;
  font-family: "Poppins";
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #336cfb;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  width: 50%;

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
  }
}

.preview {
  margin: 2.5rem 0px;
  padding: 1rem 2.5rem;
  display: flex;
  &__left,
  &__right {
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
.form-preview {
  padding: 1rem 2.5rem;
  background-color: #fff;
  display: flex;
  &__left,
  &__right {
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
}

// ************************************** Publish ************************************** //

.publish {
  margin-top: 2.5rem;
  background: #336cfb;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    padding: 15px 0rem;
    padding-right: 10px;
  }
}

.conitinue {
  // margin-top: 5rem;
  bottom: 3rem;
  right: 2rem;
  position: absolute;
  background: #336cfb;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    padding: 15px 0rem;
    padding-right: 10px;
  }
}

.publish--text {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #eceaff;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 8px 12px;
    width: 75%;
    line-height: 16px;
  }
}

.publish--button {
  button {
    transition: all 0.3s linear;
    text-transform: none;
    color: #336cfb;
    background-color: #fff;
    font-family: "Poppins" !important;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2px;
    padding: 8px 18px;
    &:hover {
      background-color: #fff;
    }

    @media (max-width: 768px) {
      font-size: 10px;
      padding: 8px 12px;
      min-width: 100px;
      min-height: 35px;
    }
  }

  .MuiIconButton-root {
    color: #fff !important;
  }
}

// ************************************** Form Budget ************************************** //
.create-formBudget {
  display: flex;
  padding: 2.5rem 2.5rem;
  width: 100%;
  background-color: #fff;
  span {
    margin-right: 1.5rem;
  }
}
