.expected-preview {
}

.expected-clicks {
  width: 410px;
  @media (max-width: 400px) {
    width: 270px;
  }

  div {
    position: relative;
    p {
      position: absolute;
      top: 26%;
      left: 33%;
      font-weight: 600;
      font-size: 25px;
      letter-spacing: 0.2px;
      color: #336cfb;
      width: 100px;
      text-align: center;
      @media (max-width: 400px) {
        font-size: 20px;
        top: 30%;
        left: 19%;
      }
    }

    img {
      margin-top: 2rem;
      margin-left: -2.6rem;
      width: 455px;
      height: 285px;

      @media (max-width: 400px) {
        width: 360px;
        height: 228px;
        margin-left: -5rem;
      }
    }
  }
}
