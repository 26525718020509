.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 1rem 1rem;
    //  justify-content: flex-start;
  }
}

.logo-area {
  width: 10%;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: #185adb;

  span {
    height: 40px;
    width: 1px;
    background-color: #336cfb;
    margin-right: 8px;
  }

  @media (max-width: 800px) {
    position: absolute;
    top: 8%;
    left: 10%;
  }
}

.nav-links {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a,
  p {
    text-decoration: none;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #52575c;
    margin-right: calc(100vw - 96vw);
    cursor: pointer;

    &:last-child {
      margin-right: 1rem;
    }
  }

  button {
    width: 140px;
    height: 36px;
    color: white;
    background: #8255ff;
    box-shadow: 0px 4px 9px rgba(24, 90, 219, 0.4);
    border-radius: 4px;

    font-weight: 500;
    font-size: 12px !important;
    line-height: 22px;

    &:hover {
      background: #8255ff;
    }
  }

  @media (max-width: 800px) {
    padding-left: 1rem;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 6rem;
    a,
    p {
      margin-top: 20px;
    }

    button {
      margin-top: 20px;
    }
  }
}

.dn {
  width: 100%;
  padding: 0;
  display: flex;
  @media (max-width: 800px) {
    display: none;
  }
}

.menu-icon {
  display: none !important;
  position: fixed;
  top: 4%;
  left: 3%;
  cursor: pointer;
  z-index: 10;
  transform: translate3d(0, 0, 100);

  @media (max-width: 800px) {
    display: block !important;
  }
}

.social-logo {
  width: 4rem;
}
