
.question_form{    
    height:100%;
    
}

.section{
    margin: auto;
    width:100%;

}


.MuiCollapse-wrapper{
    padding-bottom:10px !important;
}


.question_form_top{
    
    background-color: white;
    border-top:8px solid rgb(103, 58, 183);
    border-radius: 8px;
    padding:30px 25px;
    text-transform: capitalize;

    
}

.question_form_top_name{
    box-sizing: border-box;
   
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;

    line-height: 135%;
    width: 100%;
    border:none;
    outline:none;
    border-bottom:1px solid #F4F4F9;
    color:black;
    height:35px;
   
}

.question_form_top_desc{
    box-sizing: border-box;
     margin-top: 10px;
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 40px;

  
    width: 100%;
    border:none;
    outline:none;
    border-bottom:1px solid #F4F4F9;
    color:black;
    height:20px;
}

.question_boxes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.add_question{
  
    background-color: white;
    
    border-radius: 8px;
    padding:25px 22px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    /* padding-bottom:10px; */
    padding-top:0px;
    width:93%;
    margin-left:10px;
    
}

.add_question_top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.question{
    box-sizing: border-box;
    margin-top: 10px;
   font-family: 'Google Sans',Roboto,Arial,sans-serif;
   font-size: 15px;
   font-weight: 400;
   flex:1;
   line-height: 40px;
   width: 40%;
   border:none;
   outline:none;
   color:black;
   height:40px;
   margin-right: 10px;
   line-height: 10px;
   padding:10px;
}

.top_header{
    font-Size:15px;
    color:grey;
   font-family: 'Google Sans',Roboto,Arial,sans-serif;
   margin-Left:6px;
   font-weight: 400;
}
.points{
    box-sizing: border-box;
    /* margin-top: 10px; */
   font-family: 'Google Sans',Roboto,Arial,sans-serif;
   font-size: 15px;
   font-weight: 400;

   /* line-height: 40px; */
   width:8%;
   border:1px solid blue;
   outline:none;
   color:black;
   height:40px;
   margin-right: 10px;
   line-height: 10px;
   padding:4px;
}


.question:focus{
    border-bottom:1px solid rgb(103, 58, 183);
  
    background-color:#F4F4F9;
}



.select{
    height:40px;
    width:230px;
    border:0.5px solid ;
    padding:5px 15px;
    color:black;
    margin-left:10px;
    margin-right:10px;
    border:1.5px solid #F4F4F9;
    border-radius:3px;
background:transparent;
}
.MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    display: flex;
    align-items: center;
}
.MuiSlider-root{
    color: #336CFB !important;
    width: 50% !important;
}

.menuitem{
  color: rgba(118, 118, 118, 0.3);
}

.add_footer{
    display:flex;justify-content: space-between;
}
.add_question_bottom{
    margin-top:12px;
    border-top:1px solid rgba(118, 118, 118, 0.3);
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex:1;
}

.add_question_bottom_left{
    margin-top:12px;
    border-top:1px solid rgba(118, 118, 118, 0.3);
    display:flex;
    justify-content: flex-end;
    align-items: center;
}


.add_question_body{
    display:flex;
    
    align-items: center;
}

.text_input{
    outline:none;
    border:none;
    height:40px;
    /* width:490px; */
    flex:"auto";
    font-family: Roboto,Arial,sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .2px;
    
    color: #202124;
}


.text_input:focus{
    border-bottom:1.5px solid rgb(103, 58, 183);

}



.question_edit{
    background-color:#F4F4F9;
    display:flex;
    flex-direction: column;
    margin-right:12px;
    margin-top: 10px;
    margin-bottom: 0;
    padding:1px;
    border-radius:3px;
    height:180px;
    width:35px;
    

}

.edit{
    color:#5f6368;
    padding:8px 5px;
    
}

.saved_questions
{
    background-color: white;

    border-radius: 8px;
    padding:20px 2px;
    text-transform: capitalize;
    display:flex;
    flex-direction: column;

    
}


.add_border{
    border-left:6px solid #4285f4;
}

.save_form{
    margin:15px;
}